<template>
  <div
    v-if="isShow"
    :class="isMain ? 'main_bg' : 'cont_body'"
  >
    <div
      v-if="isMain"
      class="m_top_area"
    >
      <div
        class="left"
        style="margin-top:-24px;padding:10px"
      >
        <a
          id="menu_btn"
          href="javascript:void(0)"
          title="메뉴"
          class="btn_top_menu"
        >
          <span class="ir">메뉴</span>
        </a>
      </div>
      <div class="right">
        <router-link
          to="/ContentsList?cate_a=search"
          class="btn_top_sch"
          style="margin:-14px 10px 10px"
          title="검색으로이동"
        >
          <span class="ir">검색</span>
        </router-link>
      </div>
    </div>
    <div
      v-else
    >
      <TitleBar
        :top-title="title"
        :top-left="isCheckboxMode ? '' : 'back'"
      />

      <FavFolderPopup
        v-if="!isShowCateA"
        ref="FavFolderPopup"
        :title="favFolderTitle"
        @chg-fav="chgFav"
      />
      <div class="sch_cate_frame">
        <div class="sch_cate">
          <div class="input_area">
            <div class="input_wrap">
              <input
                v-model="keyword"
                type="text"
                class="sch_text sch_d"
                :placeholder="getLang('검색어를 입력하세요')"
                @keyup.enter="onClickSearch"
              >
            </div>
            <a
              title="검색"
              class="btn_top_sch"
              href="javascript:void(0)"
              @click="onClickSearch"
            >
              <span class="ir">검색</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- cateB를 고르는 페이지 -->
    <div
      v-if="isShowCateA"
      ref="list_cateB"
      class="flex_scroll"
    >
      <div
        v-if="isMain"
        class="main_title"
      >
        {{ getLang(title) }}
      </div>
      <div class="cate_b_ctnr">
        <div
          v-for="el in listCateBWithALL"
          :key="el.cate_uid"
          class="cate_b"
          @click="onClickCateB(el.cate_uid)"
        >
          <div>
            <img src="/img/ico/ic_collection_folder.png">
            <img
              :src="`/img/img/${el.icon ? el.icon : `${cate_a}.${el[0]}.jpg`}`"
              @error="function(ev){ev.target.src='/img/img/cate_b/default.jpg'}"
            >
            <div>
              {{ getLang(el.cate_name) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 콘텐츠 목록이 나오는 페이지 -->
    <div
      v-if="!isShowCateA"
      ref="list_conts"
      class="flex_scroll"
      style="position:relative"
    >
      <div
        class="content_list"
        @selectstart.prevent="function(){}"
        @contextmenu.prevent="function(){}"
      >
        <div
          v-if="isCheckboxMode"
          style="position:fixed; top:0; right:0; width:100%; height:54px; z-index:10"
        >
          <div style="margin:22px 30px 0 15px; text-align:right; color:#fff">
            <div
              style="position:absolute"
              @click.stop="isCheckboxMode = false"
            >
              {{ getLang('취소') }}
            </div>
            {{ listChecked.length }} / {{ contsList.length }}
            <div
              style="top:20px; right:7px"
              :class="{ ico_check: contsList.length == listChecked.length, checkbox: true }"
              @click.stop="onClickCheckboxALL"
            />
          </div>
        </div>
        <ul ref="scroller">
          <li
            v-for="el in contsList"
            :key="`${el.uu}-${el.uid}`"
          >
            <div
              v-if="isCheckboxMode"
              class="checkfrm1"
              @click.stop="onClickCheckbox(`${el.uu}-${el.uid}`)"
            >
              <div
                class="checkfrm2"
                :style="listChecked.indexOf(`${el.uu}-${el.uid}`) !== -1 ? 'background-color:#00000099; border:3px solid #000' : ''"
              >
                <div
                  :class="{ ico_check: listChecked.indexOf(`${el.uu}-${el.uid}`) !== -1, checkbox: true }"
                />
              </div>
            </div>
            <div
              class="thumb"
              @click.stop="onClickItem(el.uu, el.uid)"
              @mousedown.stop="onTouchStart(`${el.uu}-${el.uid}`)"
              @mouseup.stop="onTouchEnd()"
              @mousemove.stop="onTouchEnd()"
              @touchstart.stop="onTouchStart(`${el.uu}-${el.uid}`)"
              @touchend.stop="onTouchEnd()"
              @touchmove.stop="onTouchEnd()"
            >
              <img
                :src="isNaN(el.uid) || el.type == 'video' ? '/img/img/mov_icon.png' : `${apiHost}/file/thumb?uu=${el.uu||0}&uid=${el.uid}`"
                onerror="onImageError(this)"
              >
              <div
                class="name"
                :class="{'active': el.fav_cnt}"
              >
                {{ el.nm }}
                <div class="ico_favor" />
              </div>
              <div class="conts_info">
                <div
                  v-if="el.ani"
                  class="ico_ani"
                />
                <div
                  v-if="el.snd == 'Y'"
                  class="ico_snd"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <infinite-loading
        v-if="isShowLoading"
        ref="loadMore"
        @infinite="loadMore"
      >
        <template slot="no-more">
          {{ getLang('데이터의 마지막입니다.') }}
        </template>
        <template slot="no-results">
          {{ getLang(contsList.length ? '데이터의 마지막입니다.' : '데이터가 없습니다.') }}
        </template>
        <template slot="error">
          {{ getLang('오류가 발생하였습니다.') }}
        </template>
      </infinite-loading>
      <div
        v-if="isCheckboxMode"
        class="btns_wrap fixed btns_view"
      >
        <ul
          :class="{
            col2: cate_a != 'myCate',
            col3: cate_a == 'myCate',
          }"
        >
          <li>
            <button
              class="btn_w active"
              @click="onClickAddFav(true)"
            >
              <i class="ico_favor" /> {{ getLang('즐겨찾기 추가') }}
            </button>
          </li>
          <li>
            <button
              class="btn_w"
              @click="onClickAddFav(false)"
            >
              <i class="ico_favor" /> {{ getLang('즐겨찾기 삭제') }}
            </button>
          </li>
          <li v-if="cate_a == 'myCate'">
            <button
              class="btn_w"
              @click="onClickDelConts()"
            >
              <i class="ico_del" /> {{ getLang('삭제하기') }}
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="isMain"
    >
      <div class="menu_footer">
        <img
          src="/img/img/logo_01.png"
          style="width:100px"
        >
      </div>
      <Menu id="drawer" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import Menu from '@/components/Menu.vue';
import TitleBar from '@/components/navi/TitleBar.vue';
import FavFolderPopup from '@/components/popup/FavFolderPopup.vue';
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';

//const COL_COUNT = 2;
//const IMAGE_RATIO = 640 / 480;

export default {
  metaInfo () {
    return {
      title: this.title,
    };
  },
  components: {
    Menu,
    TitleBar,
    FavFolderPopup,
    InfiniteLoading,
  },
  data () {
    return {
      isShow: false,
      cate_a: this.$route.query.cate_a,
      cate_b: this.$route.query.cate_b,
      from_main: this.$route.query.from_main,
      isShowLoading: false,
      isSearchOnly: this.$route.query.cate_a == 'search',
      keyword: '',
      favFolderTitle: '',
      isCheckboxMode: false,
      listChecked: [],
    };
  },
  computed: {
    ...mapState({
      apiHost: state => state.apiHost,
      contsList: state => state.contsList,
    }),
    ...mapGetters({
      getLang: 'getLang',
      nameSelectedCateA: 'getNameSelectedCateA',
      nameSelectedCateB: 'getNameSelectedCateB',
      listCateB: 'getListCateB',
      listCollec: 'getListFavInfo',
      getCateOne: 'getCateOne',
      favInfo: 'getFavInfo',
      mainTP: 'getMainTP',
    }),
    title () {
      let title;

      title = this.getLang(this.nameSelectedCateA);

      if (this.cate_b && this.listCateB.length) {
        this.listCateB.some((el) => {
          const nameB = (
            this.cate_b == 'all' ? '전체'
              : this.cate_b == el.cate_uid ? el.cate_name : ''
          );

          if (nameB) {
            title += ` (${this.getLang(nameB)})`;
            return true;
          }
        });
      }

      return title;
    },
    isMain () {
      return this.cate_a == this.mainTP && !this.cate_b;
    },
    isShowCateA () {
      return !this.cate_b && this.listCateB && this.listCateB.length;
    },
    listCateBWithALL () {
      return [
        {
          cate_name: '전체',
          cate_uid: 'all',
          icon: this.getCateOne(this.cate_a).icon_all,
        },
        ...this.listCateB,
      ];
    },
  },
  async mounted () {
    if (process.env.NODE_ENV == 'production') try { console.clear(); } catch (e) {}

    this.setSelectedCateA(this.cate_a || 'all');
    this.setSelectedCateB(this.cate_b || 'all');

    await this.loadBasic();

    if (this.from_main && this.mainTP != this.cate_a) {
      this.$router.replace({ path: '/' });
    } else {
      this.isShow = true;

      if (this.isShowCateA) {
        this.setKeyword('');
      } else {
        this.keyword = this.$store.state.keyword;
      }

      this.isShowLoading = !this.isSearchOnly || this.keyword;

      this.$nextTick(() => {
        if (this.$refs.list_cateB && this.$store.state._scrollTop_cateB) {
          this.$refs.list_cateB.scroll(0, this.$store.state._scrollTop_cateB);
          this.$store.state._scrollTop_cateB = 0;
        }
        if (this.$refs.list_conts && this.$store.state._scrollTop_conts) {
          this.$refs.list_conts.scroll(0, this.$store.state._scrollTop_conts);
          this.$store.state._scrollTop_conts = 0;
        }
      });
    }
  },
  methods: {
    ...mapMutations({
      clearContsList: 'clearContsList',
      setSelectedCateA: 'setSelectedCateA',
      setSelectedCateB: 'setSelectedCateB',
      setKeyword: 'setKeyword',
    }),
    ...mapActions({
      loadBasic: 'loadBasic',
      loadContsList: 'loadContsList',
      loadAddedFav: 'loadAddedFav',
      loadFavInfo: 'loadFavInfo',
      chgShowList: 'chgShowList',
    }),
    loadMore (loadMoreState, callback) {
      this.loadContsList().then(res => {
        if (res.length) {
          loadMoreState.loaded();
        } else {
          loadMoreState.complete();
        }
      });

      if (callback) callback();
    },
    onClickItem (uu, uid) {
      this.$store.state._scrollTop_conts = this.$refs.list_conts.scrollTop;

      this.$router.push({
        path: `/Content?uu=${uu}&uid=${uid}`
      });
    },
    onClickSearch () {
      this.listChecked = [];
      this.clearContsList();
      this.setKeyword(this.keyword);

      if (this.isShowCateA) {
        this.$router.push({ path: '/ContentsList', query: { cate_a: this.cate_a, cate_b: 'all' } });
      } else {
        if (this.isShowLoading) {
          this.$refs.loadMore.stateChanger.reset();
        } else {
          this.isShowLoading = true;
        }
      }
    },
    onClickCate () {
      this.$refs.catePopup.show();
    },
    onChangeCate (cateB) {
      this.clearContsList();
      this.setSelectedCateB(cateB);

      this.$refs.loadMore.stateChanger.reset();
    },
    onClickCateB (cateB) {
      this.$store.state._scrollTop_cateB = this.$refs.list_cateB.scrollTop;

      this.clearContsList();
      this.$router.push({ path: '/ContentsList', query: { cate_a: this.cate_a, cate_b: cateB } });
    },
    onTouchStart (_uuid) {
      this.longClickUid = _uuid;
      this.longClickTime = setTimeout(() => {
        if (this.longClickUid === _uuid) this.isCheckboxMode = !this.isCheckboxMode;
        if (this.isCheckboxMode) this.listChecked = [_uuid];
      }, 500);
    },
    onTouchEnd () {
      delete this.longClickUid;
      clearTimeout(this.longClickTime);
    },
    onClickCheckboxALL () {
      this.listChecked = (this.contsList.length == this.listChecked.length)
        ? [] : this.contsList.map((el) => `${el.uu}-${el.uid}`);
    },
    onClickCheckbox (_uuid) {
      if (this.listChecked.indexOf(_uuid) !== -1) {
        this.listChecked = this.listChecked.filter((el) => el !== _uuid);
      } else {
        this.listChecked.push(_uuid);
      }
    },
    onClickAddFav (_isAdd) {
      this.isAddFav = _isAdd;
      this.favFolderTitle = this.isAddFav ? '즐겨찾기 추가' : '즐겨찾기 삭제';

      if (this.favInfo.filter(el => el.parent_uid).length) {
        this.$refs.FavFolderPopup.show();
      } else {
        this.chgFav(0);
      }
    },
    async chgFav (_fid) {
      this.verifyListChecked();

      const rs = await axios.post(`${this.$store.state.apiHost}/api/addFav`, {
        fid: _fid,
        arr_uuid: this.listChecked,
        isAdd: this.isAddFav,
      });

      let msg;
      if (rs.data.result == 'OK') {
        this.chgShowList({ isAdd: this.isAddFav, fid: rs.data.fid, arrConts: this.listChecked });
        if (this.isAddFav) this.loadFavInfo(true);
      } else if (rs.data.result == 'NOT EXIST') {
        msg = this.getLang('선택된 폴더를 찾을 수 없습니다.');
      } else {
        msg = this.getLang('시스템 장애가 발생하였습니다.');
      }
      if (msg) this.showAlert(this.getLang('즐겨찾기'), msg);

      this.isCheckboxMode = false;
    },
    onClickDelConts () {
      this.verifyListChecked();

      this.showAlert(this.getLang('삭제하기'), this.getLang('콘텐츠 %s개를 삭제하시겠습니까?').replace('%s', this.listChecked.length), async () => {
        const res = await axios.post(`${this.$store.state.apiHost}/api/delete_conts`, { arr_uuid: this.listChecked });
        if (res.data.result == 'OK') {
          this.$store.commit('delContsList', this.listChecked);
          this.isCheckboxMode = false;
        } else {
          this.showAlert(this.getLang('삭제하기'), this.getLang('삭제할 수 없습니다.'));
        }
      }, 2);
    },
    verifyListChecked () {
      const arr = [];

      this.contsList.forEach((el) => {
        const uuid = `${el.uu}-${el.uid}`;
        if (this.listChecked.indexOf(uuid) !== -1) {
          arr.push(uuid);
        }
      });

      this.listChecked = arr;
    },
  },
};
</script>

<style scoped>
  .main_title {
    margin-top: 50px;
    text-align: center;
    font-size: 4rem;
    color: #fff;
  }

  /* 콘텐츠 목록 페이지 */

  .content_list .thumb {
    position:absolute; top:0; left:0; width:100%; height:100%; background-color:#ffffff01
  }
  .content_list .thumb .ico_favor {
    position:absolute; top:11px; right:5px; width:17px; height:17px
  }
  .content_list .thumb .conts_info {
    position:absolute; top:3px; left:3px
  }
  .content_list .thumb .conts_info div {
    float:left; width:min(50px, max(20px, 4vw)); height:min(50px, max(20px, 4vw))
  }
  .content_list .checkfrm1 {
    position:absolute; width:100%; height:100%; border:1px solid #fff; z-index:1
  }
  .content_list .checkfrm2 {
    width:100%; height:100%; background-color:#ffffff22; border:2px solid #aaa
  }
  .content_list .checkbox {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    background-color: #fff;
    border-radius: 3px;
  }
  .btns_wrap button {
    color: #fff;
    background-color: #000;
  }

  /* cateB 선택 페이지 */

  .cate_b_ctnr {
    padding: 50px 30px;
  }
  .cate_b {
    position: relative;
    width: 33%;
    display: inline-block;
    cursor: pointer;
  }
  .cate_b>div {
    margin: 30px;
    width: calc(100% - 60px);
    border-radius: 50px 0 50px 50px;
    overflow: hidden;
    background-color: #fff;
  }
  .cate_b>div>img:nth-child(1) {
    position: absolute;
    right: 30px;
    width: 40px;
    height: 40px;
  }
  .cate_b>div>img:nth-child(2) {
    width: 100%;
    aspect-ratio: 3;
  }
  .cate_b>div>div {
    padding: 0 15px;
    width: 100%;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-top: 1px solid #eee;
  }
  .cont_body .cate_b>div {
    box-shadow: 2px 2px 10px 1px #bbb;
  }
  @media screen and (max-width: 1000px) {
    .cate_b>div {
      margin: 10px;
      width: calc(100% - 20px);
      border-radius: 20px 0 20px 20px;
    }
    .cate_b>div>img:nth-child(1) {
      right: 10px;
    }
  }
  @media screen and (max-width: 800px) {
    .cate_b {
      width: 50%;
    }
  }
  @media screen and (max-width: 600px) {
    .cate_b_ctnr {
      padding: 20px 0;
    }
    .cate_b>div {
      border-radius: 10px 0 10px 10px;
    }
    .cate_b>div>img:nth-child(1) {
      width: 30px;
      height: 30px;
    }
  }
</style>
